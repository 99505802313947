$(function () {
  $("#gnav-menu-btn").click(function () {
    $(this).toggleClass("active");
    $("#nav").toggleClass("panelactive");
    $("#nav-bg").toggleClass("panelactive");
  });
  $("#nav .gnav-list .gnav-list__item a").on("click", function() {
    $("#gnav-menu-btn").toggleClass("active");
    $("#nav").toggleClass("panelactive");
    $("#nav-bg").toggleClass("panelactive");
  });
  // 指定クラス内のテキストをSPANで囲む
  $(".letter-span").letterSpan();

  FixedRecruitMenu();
  $(window).resize(function () {
    FixedRecruitMenu();
  });

  $(window).scroll(function () {
    FixedMenu();
    console.log('scroll');
    $(".fadein").each(function () {
      if ($(this).length) {
        var position = $(this).offset().top;
      }
      //var position = $(this).offset().top;
      var scroll = $(window).scrollTop();
      var windowHeight = $(window).height();
      if (scroll > position - windowHeight + 50) {
        $(this).addClass("active");
      }
    });
    $(".ttl-anime").each(function () {
      if ($(this).length) {
        var position = $(this).offset().top;
      }
      //var position = $(this).offset().top;
      var scroll = $(window).scrollTop();
      var windowHeight = $(window).height();
      if (scroll > position - windowHeight + 50) {
        $(this).addClass("active");
      }
    });
  });
  $(window).scroll();

  $(".slider-card__item__career-txt__ctl").click(function () {
    $(this).toggleClass("active");
    $(this).prev("p").toggleClass("active");
  });

  //アコーディオン
  $(".qa-list__dt").click(function () {
    $(this).toggleClass("active");
    $(this).next(".qa-list__dd").slideToggle();
  });

  $('#link-service').click(function () {
    const speed = 600;
    let href = $(this).attr("href");
    let target = $("#srv");
    let position = target.offset().top;
    $("body,html").animate({ scrollTop: position }, 1000);
    return false;
  });

  //　URLにアンカー取得
	var urlHash = location.hash;

	//　アンカーが場合
	if(urlHash){
    let target = $(urlHash);
    let position = target.offset().top;
    $("body,html").animate({ scrollTop: position }, 1000);
    return false;
	}


});

$(window).on("load", function () {});

function FixedMenu() {
  var headerH = $("#header").outerHeight(true);
  var scroll = $(window).scrollTop();
  var winodwH = $(window).height();
  var bodyH = $('body').height();
  if ($("#cts-recruit").length) {
    var btm_recruit = $("#cts-recruit").offset().top;
  }

  if (scroll >= headerH) {
    $("#header").addClass("fixed");
    $("#pickup-news").fadeOut();
  } else {
    $("#header").removeClass("fixed");
    $("#pickup-news").fadeIn();
  }


}

function FixedRecruitMenu() {
  var menuW = $("#recruit-wrap").outerWidth();
  var winodwW = $(window).width();
  var fix_right_px = (winodwW - menuW) / 2;
  $("#recruit-sidemenu").css("right", fix_right_px);
}
function FixedAboutusBg() {
  // if ($("#aboutus-bg").length) {
  //   var bgH = $("#aboutus-bg").offset().top;
  // }
  // var headerH = $("#header").outerHeight(true);
  // var scroll = $(window).scrollTop();
  // var winodwH = $(window).height();
  // var bodyH = $('body').height();
  // console.log(scroll);
  // console.log(bgH);
  // if (scroll >= bgH - headerH) {
  //   $("#aboutus-bg .aboutus__bg-earth__img");
  // } else {
  //   $("#aboutus-bg .aboutus__bg-earth__img").css({'position':'fixed','top': '10px' ,'left': '10px'});
  // }
}


/**
 * 記事一覧
 * もっとみるボタンでの記事取得Ajax
 */
$(function($) {
  var page = 2;

  var postType = 'post';
  var ajaxUrl = '/corporate/wp-admin/admin-ajax.php';
  //var ajaxUrl = '/wp-admin/admin-ajax.php';
  var $moreButton = $('#moreButton');
  var posts_per_page = 1;

  function hideMoreButton() {
    $moreButton.hide();
  }

  $moreButton.on('click', function () {
    
    if($(this).data('post-type')){
      var postType = $(this).data('post-type');
    }
    if($(this).data('posts_per_page')){
      var posts_per_page = $(this).data('posts_per_page');
    }
    
    $.ajax({
      url: ajaxUrl,
      type: 'POST',
      data: {
        action: 'load_more_posts',
        post_type: postType,
        page: page,
        posts_per_page: posts_per_page
      },
      success: function(response) {
        var result = JSON.parse(response);
        $('#moreList').append(result.html);
        page++;

        if ($('#moreList > li').length === result.total_posts) {
          hideMoreButton();
        } else if (!result.show_button) {
          hideMoreButton();
        }
      },
      error: function(xhr, status, error) {
        console.error(xhr);
      }
    });
  });

  if ($('#moreList > li').length < posts_per_page) {
    hideMoreButton();
  }
});

/**
 * 指定class内のテキストを位置文字ずつSPANで囲む
 */
$.fn.letterSpan = function() {

  $(this).each(function() {
      var text = $.trim(this.textContent),
          html = "";

      text.split("").forEach(function(v) {
          html += "<span>" + v + "</span>";
      });

      this.innerHTML = html;
  });
};